import request from '@/plugins/axios';
function pickTrue(obj) {
  const newObj = {
    ...obj,
  };
  Object.keys(newObj).forEach((item) => {
    if (!newObj[item] && newObj[item] !== 0) {
      delete newObj[item];
    }
  });
  return newObj;
}
/* ----------【all】分页车辆------------ */
export const getDriverOld = (data) => {
  return request.post(`/resource/driver/list`, pickTrue(data)).then((res) => {
    return res;
  });
};
export const getDriver = (data) => {
  return request.post(`/resource/middle/driver/list`, data).then((res) => {
    return res;
  });
};
export const addDriver = (data) => {
  return request.post('/resource/driver/add', data, { showLoading: true }).then((res) => {
    return res;
  });
};
export const getDetailDriver = (data) => {
  return request.get('/resource/driver/detail', data).then((res) => {
    return res;
  });
};
export const editDriver = (data) => {
  return request.post('/resource/driver/update', data, { showLoading: true }).then((res) => {
    return res;
  });
};
export const deleteDriver = (data) => {
  return request.post('/resource/driver/delete', data, { showLoading: true }).then((res) => {
    return res;
  });
};
export const batchDeleteDriver = (data) => {
  return request.post('/resource/driver/batch/delete', data, { showLoading: true }).then((res) => {
    return res;
  });
};
// 车辆状态数量统计
export const driverStateTotal = (data) => {
  return request.post('/resource/driver/state/total', data, { showLoading: true }).then((res) => {
    return res;
  });
};

